import {SubmitHandler, useForm} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import {useSWRConfig} from 'swr'
import * as React from 'react'

import BaseDialog, {Props as BaseDialogProps} from './BaseDialog'
import {getUserLists, updateList} from '../api/listApi'
import {useToasts} from './ToastsProvider'
import {List} from '../types'
import useList from '../hooks/useList'
import useUser from '../hooks/useUser'
import LevelMapSelector from './LevelMapSelector'

interface Props {
  onSubmit(message?: string): Promise<List | undefined>
  onClose: BaseDialogProps['onClose']
  isOpen: BaseDialogProps['isOpen']
  listId: List['id']
}

type FormData = {
  name: string
  schoolHubspotId?: List['schoolHubspotId']
  level?: List['level']
  studentsAmount?: List['studentsAmount']
}

export default function EditListDialog(props: Props) {
  const {addToast} = useToasts()
  const global = useSWRConfig()
  const {data, mutate} = useList(props.listId)

  const form = useForm<FormData>({shouldUnregister: true})

  const {
    handleSubmit,
    formState: {errors},
    setValue,
    register,
  } = form

  const user = useUser()

  const currentStudents = data?.studentsAmount
  const currentSchool = data?.schoolHubspotId
  const currentLevel = data?.level
  const currentName = data?.name
  const isOpen = props.isOpen

  const handleListUpdate: SubmitHandler<FormData> = ({
    name,
    schoolHubspotId,
    level,
    studentsAmount,
  }) => {
    if (!data) return
    const optimisticData = {
      ...data,
      name,
      schoolHubspotId,
      studentsAmount,
      level,
    }
    props.onClose()
    mutate(
      () =>
        updateList(props.listId, {
          name,
          schoolHubspotId,
          studentsAmount,
          level,
        }).then(res => {
          global.mutate('lists') // update name in sidebar
          addToast('List updated', {variant: 'success'})
          return res
        }),
      {
        rollbackOnError: true,
        optimisticData,
      },
    )
  }

  // re-populate field value when editing starts
  // (needed for handling "cancel" correctly)
  React.useEffect(() => {
    if (isOpen && currentName) {
      setValue('name', currentName)
      setValue('level', currentLevel)
      setValue('schoolHubspotId', currentSchool)
      setValue('studentsAmount', currentStudents)
    }
  }, [
    currentStudents,
    currentSchool,
    currentLevel,
    currentName,
    setValue,
    isOpen,
  ])

  return (
    <BaseDialog
      onClose={props.onClose}
      isOpen={props.isOpen}
      title="Edit list details"
      size="lg">
      <form className="mt-4 " onSubmit={handleSubmit(handleListUpdate)}>
        <label className="text-sm text-slate-500" htmlFor="name">
          List name
        </label>
        <input
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          className="block w-full border-b-2 text-lg outline-none focus:border-blue-700"
          type="text"
          {...register('name', {
            setValueAs: value => value.trim(),
            validate: value =>
              getUserLists().then(lists => {
                if (value === currentName) return true
                return lists.some(l => l.name === value)
                  ? `You already have a list called "${value}"`
                  : true
              }),
            required: 'Required',
          })}
        />
        <div className="mt-1 h-5">
          <ErrorMessage
            className="text-xs text-red-600"
            errors={errors}
            name="name"
            as="div"
          />
        </div>

        <div className="">
          <label className="hidden text-sm text-slate-500" htmlFor="school">
            School
          </label>

          <input
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            className="leading-2 hidden h-9 w-full border-b-2 border-slate-300 bg-transparent text-base outline-none focus:border-blue-700"
            type="text"
            disabled={!user.data || !user.data || user.data.type !== 'admin'}
            {...register('schoolHubspotId', {required: 'Required'})}
          />
          <div className="mt-1 h-5">
            <ErrorMessage
              className="text-xs text-red-600"
              errors={errors}
              name="schoolHubspotId"
              as="div"
            />
          </div>
        </div>

        <div className="mt-0 grid grid-cols-2 gap-8">
          <LevelMapSelector form={form} />

          <div className="">
            <label className="text-sm text-slate-500" htmlFor="grade">
              Number of students
            </label>
            <input
              data-asd="asd"
              type="text"
              {...register('studentsAmount', {
                min: 0,
                required: 'Required',
              })}
              className="leading-2 block h-9 w-full border-b-2 border-slate-300 bg-transparent text-base outline-none focus:border-blue-700"
            />
            <div className="mt-1 h-5">
              <ErrorMessage
                className="text-xs text-red-600"
                errors={errors}
                name="studentsAmount"
                as="div"
              />
            </div>
          </div>
        </div>

        <div className="mt-3 flex gap-4">
          <button
            className="flex items-center gap-2 rounded-md bg-blue-500 px-6 py-3 text-sm font-semibold text-white outline-none ring-blue-400 ring-offset-2 hover:bg-blue-600 focus-visible:ring-2 disabled:bg-blue-300"
            type="submit">
            Save
          </button>
          <button
            className="rounded-md px-4 py-3 text-sm font-semibold text-blue-500 underline outline-none ring-blue-400 ring-offset-2 hover:text-blue-600 focus-visible:ring-2 disabled:text-blue-300"
            onClick={props.onClose}
            type="button">
            Cancel
          </button>
        </div>
      </form>
    </BaseDialog>
  )
}
