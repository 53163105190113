import {FieldValues, UseFormReturn} from 'react-hook-form'

import {ErrorMessage} from '@hookform/error-message'
import useSchool from '../hooks/useSchool'
import useUser from '../hooks/useUser'
import {SearchLevel} from '../types'
import useLevelMap from '../hooks/useLevelMap'

export type GradeSelectorFormData = {
  level?: SearchLevel
}

type Props<T extends FieldValues> = {
  screenLoading?: boolean
  form: UseFormReturn<T>
}

export default function LevelMapSelector<T extends FieldValues>({
  screenLoading = false,
  form: {
    formState: {errors},
    register,
  },
}: Props<T>) {
  const user = useUser()
  const schoolFromAPI = useSchool()
  const {data: levelMapFromApi} = useLevelMap()

  const loading = screenLoading || user.isLoading || schoolFromAPI.isLoading

  return (
    <div className="">
      <label className="text-sm text-slate-500" htmlFor="academicLevel">
        Academic Level
      </label>
      <select
        {...register('level' as any, {required: 'Required'})}
        disabled={loading}
        className="leading-2 block h-9 w-full border-b-2 border-slate-300 bg-transparent pl-0 text-base outline-none focus:border-blue-700">
        <option />
        {levelMapFromApi?.map(level => {
          const value = level.code
          const label = `${level.code} - ${level.description}`
          return (
            <option
              selected={user.data?.selectedGrade === value}
              value={value}
              key={level._id}>
              {label}
            </option>
          )
        })}
      </select>
      <div className="mt-1 h-5">
        <ErrorMessage
          className="text-xs text-red-600"
          errors={errors}
          name={'level' as any}
          as="div"
        />
      </div>
    </div>
  )
}
